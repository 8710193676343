<template>
    <div v-loading="loading">
        <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
        <el-row :gutter="20" v-for='(item, index) in newData' :key='index' class='list content'>
            <div  class='scientificName' >{{datas.data7}}</div >
            <el-col :span="12" v-for="(itemData,index) in item" :key="'i'+index" class='flex'>
                <span>{{itemData.name || "暂无数据" }}</span>
                <span>{{itemData.data || "暂无数据"}}</span>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
export default {
    data() {
        return {
            newData: [],
            datas: '',
            breadList: [{
                name: '数据查询'
            }, {
                name: '种质资源数据',
                path: '/germplasmResource'
            }, {
                name: '详情'
            }],
            loading: true
        };
    },
    components: {
        nfBreadcrumb
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        async getDetail() {
            this.loading = true;
            const api = this.$fetchApi.germplasmMenuDetails.api + this.$route.query.id;
            const data = await this.$fetchData.fetchPost({}, api, 'json');
            this.loading = false;
            const datas = data.result.datas;
            const dict = data.result.dictionaries;
            this.id = data.result.datas.id;
            const arr = [];
            dict.forEach(descData => {
                (desc => {
                    const obj = {};
                    obj.name = desc.fieldName;
                    obj.data = datas[desc.data];
                    obj.id = datas.id;
                    arr.push(obj);
                })(descData);
            });
            this.newData.push(arr);
            this.datas = datas;
            console.log(this.datas );
        }
    }
};
</script>
<style lang="less" scoped>
.content{
    width:1180px;
    margin:40px auto 50px !important;
    .scientificName{
        width: 1140px;
        background-color: rgba(180,209,255,.3);
        border-radius: 4px;
        font-size: 18px;
        color: #448afc;
        padding: 20px;
        margin: 0 auto 30px;
    }
    span{
        padding: 20px 0;
        font-size:16px;
        text-align: center;
        border-top:1px solid #e3e3e3;
    }
    span:first-child{
        width:30%;
        display: inline-block;
        background: #fafafa;
        border-left:1px solid #e3e3e3;
        border-right:1px solid #e3e3e3;
    }
    span:last-child{
        width:70%;
        display: inline-block;
        border-right:1px solid #e3e3e3;
    }
    /deep/ .el-col{
        padding: 0 !important;
        margin:0 !important;
        
    }
    /deep/ .el-col:nth-last-child(2) {
        border-bottom:1px solid #e3e3e3;
    }
    /deep/ .el-col:nth-last-child(1) {
        border-bottom:1px solid #e3e3e3;
    }
    /deep/ .el-col:nth-child(2n) span:last-child{
        border-right:none;
    }
    .list{
        margin:50px 0 100px;
    }
}
</style>